<template>
      <Catalog @new="newEntity" v-model="entity" :validate="validate" name="Familia" :rules="rules" :headers="headers" :inputs="inputs" />
</template>
<script>
import { InventoryFamily } from '../../../models/inventarios/InventoryFamily';
import Catalog from "../../../components/templates/BasicCatalog.vue";
import Session from "../../../mixins/sessionMixin";
import { HeaderGrid, Rule, CatalogInput, ValidateForm } from '../../../utilities/General';
import { InventoryWarehouse } from '../../../models/inventarios/InventoryWarehouse';
import { InventorySubWarehouse } from '../../../models/inventarios/InventorySubWarehouse';

export default {
      data() {
    return {
      inventorysubwarehouse: [],
      inventorywarehouses: [],
      entity: new InventoryFamily(),
      inputs: [
        new CatalogInput("Nombre", "name", "text", {cols: 6}),
        new CatalogInput("Descripcion", "key_name", "text", {cols: 6}),
        new CatalogInput("Almacen", "id_inventory_warehouse", "dropdown", { options: [], options_value: 'id', options_label: 'name', cols: 4}),
        new CatalogInput("Sub Almacen", "id_inventory_subwarehouse", "dropdown", { options: [], options_value: 'id', options_label: 'name', cols: 4})
      ],
      rules: [
        new Rule({ name: "name" }),
        new Rule({ name: "key_name" }),
        new Rule({ name: "id_inventory_warehouse"}),
        new Rule({ name: "id_inventory_subwarehouse"})
      ],
      headers: [
        new HeaderGrid("Nombre", "name"),
        new HeaderGrid("Descripcion", "key_name")
      ],
      validate: new ValidateForm()
    }
  },
  mixins: [Session],
  components: { Catalog },
  methods: {
    newEntity() {
      this.entity = new InventoryFamily(this.session); 
      this.validate.clear();
    },
  },
  async created() {
    this.validate = new ValidateForm(this.rules);
    this.entity = new InventoryFamily(this.session); 

    this.inventorywarehouses = await new InventoryWarehouse(this.session).all();
    this.inputs[2].options = this.inventorywarehouses;

    this.inventorysubwarehouse = await new InventorySubWarehouse(this.session).all();
    this.inputs[3].options = this.inventorysubwarehouse;
  }
};
</script> 
 
<style scoped lang="scss">
</style > 
  
